<template>
    <div>
        <div>
            <h3 class="font-weight-bold">Anleitungen</h3>
            <div class="pb-3">
                <div>
                    <a class="font-weight-bold" @click.prevent="downloadFile('Monteur Schulung_.pdf')" href="#">
                        > Rollout Ausführungen <span style="font-weight: normal;">(Gesamtanleitung inkl. FAQs)</span>
                    </a>
                </div>
                <div>
                    <a
                        class="font-weight-bold"
                        @click.prevent="downloadFile('SMR-TD00003-1.0_HAK_Anschlusssicherungen.pdf')"
                        href="#"
                    >
                        > Bestimmung von Anschlusssicherungen
                    </a>
                </div>
                <div>
                    <a href="/help/bridge" class="font-weight-bold">
                        > Installation REtasks Bridge & UNIMOD Reader
                    </a>
                </div>
            </div>
        </div>
        <div class="pb-2">
            <h3 class="font-weight-bold">
                Update-Fenster
            </h3>
            <p v-if="isDeviceMobile">
                Folgendes wöchentliches Zeitfenster ist für Updates in REtasks, in MSP und am PLC-Netzwerk reserviert.
                In dieser Zeit darf nicht mit REtasks und MSP gearbeitet werden und die PLC-Verbindungen können
                unterbrochen sein:<br />
                <span class="font-weight-bold"
                    >Jeden Mittwoch ab 17:30 Uhr <span style="font-weight: normal;">(bis spätestens 22:00 Uhr)</span>
                </span>
            </p>
            <p v-else>
                Folgendes wöchentliches Zeitfenster ist für Updates in REtasks, in MSP und am PLC-Netzwerk reserviert.
                In dieser Zeit<br />
                darf nicht mit REtasks und MSP gearbeitet werden und die PLC-Verbindungen können unterbrochen
                unterbrochen sein:<br />
                <span class="font-weight-bold"
                    >Jeden Mittwoch ab 17:30 Uhr <span style="font-weight: normal;">(bis spätestens 22:00 Uhr)</span>
                </span>
            </p>
        </div>
        <div class="pb-4">
            <h3 class="font-weight-bold">
                Materiallager
            </h3>
            <a class="font-weight-bold" @click.prevent="downloadFile('Bestellschein_Zählermonteure.xlsx')" href="#">
                > Bestellliste
            </a>
        </div>
        <div class="contact-row">
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Landquart UW
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0814237782"> 081 423 77 82</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:logistik.praettigau@repower.com"> logistik.praettigau@repower.com</a>
                    <br />
                    Mo-Do: 07:00 - 11:45 / 13:00 - 16:15 Uhr<br />
                    Fr: <span class="friday-time">07:00 - 11:45 / 13:00 - 15:45 Uhr</span>
                </p>
            </b-col>
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Poschiavo
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0818397249"> 081 839 72 49</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:logistik.poschiavo@repower.com"> logistik.poschiavo@repower.com</a>
                    <br />
                    Mo-Do: 07:00 - 11:45 / 13:00 - 16:15 Uhr<br />
                    Fr: <span class="friday-time">07:00 - 11:45 / 13:00 - 15:45 Uhr</span>
                </p>
            </b-col>
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Küblis
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0814237782"> 081 423 77 82</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:logistik.praettigau@repower.com"> logistik.praettigau@repower.com</a>
                    <br />
                    Mo-Do: 07:00 - 11:45 / 13:00 - 16:15 Uhr<br />
                    Fr: <span class="friday-time">07:00 - 11:45 / 13:00 - 15:45 Uhr</span>
                </p>
            </b-col>
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Bever
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0818397531"> 081 839 75 31</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:logistik.engadin@repower.com"> logistik.engadin@repower.com</a>
                    <br />
                    Mo-Do: 07:00 - 11:45 / 13:00 - 16:15 Uhr<br />
                    Fr: <span class="friday-time">07:00 - 11:45 / 13:00 - 15:45 Uhr</span>
                </p>
            </b-col>
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Zernez
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0818397531"> 081 839 75 31</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:logistik.engadin@repower.com"> logistik.engadin@repower.com</a>
                    <br />
                    Mo-Do: 07:00 - 11:45 / 13:00 - 16:15 Uhr<br />
                    Fr: <span class="friday-time">07:00 - 11:45 / 13:00 - 15:45 Uhr</span>
                </p>
            </b-col>
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Ilanz
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0819262793"> 081 926 27 93</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:logistik.surselva@repower.com"> logistik.surselva@repower.com</a>
                    <br />
                    Mo-Do: 07:00 - 11:45 / 13:00 - 16:15 Uhr<br />
                    Fr: <span class="friday-time">07:00 - 11:45 / 13:00 - 15:45 Uhr</span>
                </p>
            </b-col>
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Disentis
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0819262793"> 081 926 27 93</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:logistik.surselva@repower.com"> logistik.surselva@repower.com</a>
                    <br />
                    Mo-Do: 07:00 - 11:45 / 13:00 - 16:15 Uhr<br />
                    Fr: <span class="friday-time"> 07:00 - 11:45 / 13:00 - 15:45 Uhr</span>
                </p>
            </b-col>
        </div>
        <div class="pt-2 pb-1">
            <h3 class="font-weight-bold mt-10">
                Hotlines
            </h3>
        </div>
        <div class="contact-row">
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    Smart Grid Hauptnummer <span class="font-weight-normal">(inkl. PLC)</span>
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0814237899"> 081 423 78 99</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:smartmetering@repower.com"> smartmetering@repower.com</a>
                    <br />
                    Mo-Fr: 08:00 -12:00 / 13:30 - 17:00 Uhr
                </p>
            </b-col>
            <b-col cols="12" sm="6" md="3" class="contact-col">
                <h5 class="font-weight-bold">
                    esolva Kundencenter
                </h5>
                <p>
                    <font-awesome-icon :icon="['fas', 'phone']" class="re-icon" />
                    <a href="tel:0584586095"> 058 458 60 95</a>
                    <br />
                    <font-awesome-icon :icon="['fas', 'envelope']" class="re-icon" />
                    <a href="mailto:kundencenter@repower.com"> kundencenter@repower.com</a>
                    <br />
                    Mo-Do: 08:00 - 12:00 / 13:30 - 17:00 Uhr <br />
                    Fr: <span class="friday-time">08:00 - 12:00 / 13:30 - 16:00 Uhr</span>
                </p>
            </b-col>
        </div>
        <p class="text-black-50">
            Ausserhalb der angegebenen Zeiten ist eine Erreichbarkeit möglich aber nicht garantiert!
        </p>
    </div>
</template>

<script>
export default {
    name: "Support",
    components: {},
    computed: {
        isDeviceMobile() {
            let userAgent = navigator.userAgent || window.opera;
            return this.isUserAgentMobile(userAgent);
        },
    },
    methods: {
        isUserAgentMobile(userAgent) {
            /// Identifies Mobile Device
            let mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            return mobileRegex.test(userAgent);
        },
        downloadFile(fileName) {
            const filePath = `${process.env.BASE_URL}files/${fileName}`;
            const link = document.createElement("a");
            link.href = filePath;
            link.download = fileName;
            link.click();
        },
    },
};
</script>

<style scoped>
.contact-row {
    display: flex;
    flex-wrap: wrap;
}

.contact-col {
    margin-bottom: 1rem;
    min-width: 250px;
}

@media (max-width: 1024px) {
    .contact-col {
        flex: 1 1 100%;
    }
}

@media (max-width: 768px) {
    .contact-col {
        flex: 1 1 100%;
    }
}

.friday-time {
    padding-left: 2.2em;
}
</style>
