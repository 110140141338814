<template>
    <b-navbar :toggleable="isDeviceMobile ?? 'md'" class="p-0 mb-1">
        <b-navbar-brand :to="isCurrentPathUnauthorized ? { name: 'home' } : null">
            <img v-if="isDeviceMobile" src="@/assets/images/REtasks-Logo.svg" alt="Repower Logo" height="26" />
            <img v-else src="@/assets/images/REtasks-Logo.svg" alt="Repower Logo" width="150" />
        </b-navbar-brand>

        <b-navbar-brand class="pr-3 mr-auto">
            {{ environmentTag }}
        </b-navbar-brand>

        <b-navbar-brand class="pt-0">
            <div class="mx-auto">
                <CacheManager
                    v-if="isDeviceMobile && currentUser && averageNetworkResponseTime"
                    :networkResponseTime="averageNetworkResponseTime"
                />
            </div>
        </b-navbar-brand>

        <b-navbar-brand class="pt-0">
            <div class="mx-auto">
                <ConnectivityNetwork
                    v-if="isDeviceMobile && currentUser"
                    @networkResponseTime="updatedNetworkResponseTime"
                />
            </div>
        </b-navbar-brand>

        <b-navbar-toggle target="collapse-main-nav" class="pr-0 border-0" />

        <b-collapse v-show="isUserAuthenticated" id="collapse-main-nav" is-nav>
            <b-navbar-nav class="py-1" v-if="isCurrentPathUnauthorized">
                <b-nav-item :to="{ name: 'haks-list' }" v-if="!isDeviceMobile" title="HA - HausAnschlüsse">
                    Hausanschlüsse
                </b-nav-item>
                <b-nav-item
                    v-if="doesUserHaveRole(constants.ADMIN_ROLE_ID, constants.LEITER_ROLE_ID) && !isDeviceMobile"
                    :to="{ name: 'auftrag-planung' }"
                    :exact="false"
                    title="AP - AuftragPlanung"
                >
                    Auftragsplanung
                </b-nav-item>
                <b-nav-item :to="{ name: 'execution-list' }" :exact="false" title="AA - AuftragAusführung">
                    Ausführung
                </b-nav-item>
                <b-nav-item :to="{ name: 'help-bridge' }" v-if="isDeviceMobile">Installationsanleitung</b-nav-item>
                <b-nav-item :to="{ name: 'support' }" v-if="isDeviceMobile">Support</b-nav-item>
                <b-nav-item :to="{ name: 'tech' }" :exact="false" v-if="!isDeviceMobile">Benutzerverwaltung</b-nav-item>
                <b-nav-item
                    v-if="doesUserHaveRole(constants.ADMIN_ROLE_ID, constants.LEITER_ROLE_ID) && !isDeviceMobile"
                    :to="{ name: 'orders-list' }"
                >
                    Auftragsstatus
                </b-nav-item>
                <b-nav-item
                    v-if="doesUserHaveRole(constants.ADMIN_ROLE_ID, constants.LEITER_ROLE_ID) && !isDeviceMobile"
                    :to="{ name: 'execution-raw-reads' }"
                >
                    Zählerablesungen
                </b-nav-item>
                <b-nav-item
                    v-if="doesUserHaveRole(constants.ADMIN_ROLE_ID) && !isDeviceMobile"
                    :to="{ name: 'hangfire' }"
                    :exact="false"
                >
                    Hangfire
                </b-nav-item>
                <b-nav-item
                    v-if="doesUserHaveRole(constants.ADMIN_ROLE_ID) && !isDeviceMobile"
                    :to="{ name: 'admin' }"
                    :exact="false"
                >
                    Administration
                </b-nav-item>
                <b-nav-item v-if="!isDeviceMobile" :to="{ name: 'support' }" :exact="false">
                    Support
                </b-nav-item>
            </b-navbar-nav>

            <!-- right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item v-if="isUserAuthenticated" variant="outline-primary" :to="{ name: 'logout' }">
                    Logout
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import CacheManager from "@/components/execution/CacheManager";
import ConnectivityNetwork from "@/components/execution/ConnectivityNetwork";
import constants from "@/constants/constants";
import { mapGetters } from "vuex";

/**
 * Repower Header.
 * It containts logo, application navigation bar and logout-button.
 * @displayName ReHeader
 */
export default {
    name: "ReHeader",
    components: {
        ConnectivityNetwork,
        CacheManager,
    },
    data() {
        return {
            constants: constants,
            averageNetworkResponseTime: 0,
        };
    },
    props: {
        /**
         * A boolean that sasy if a user is authenticated.
         * If you use vuex-oidc library you can pass the "oidcIsAuthenticated" getter
         */
        isUserAuthenticated: {
            type: Boolean,
            required: true,
            default: false,
        },
        /**
         * A {string} representing the title near the burger menu.
         */
        title: {
            type: String,
            required: false,
        },
    },
    beforeMount() {
        /* Avoid error while building vuepress: "ReferenceError: window is not defined"
         * https://vuepress.vuejs.org/guide/using-vue.html#browser-api-access-restrictions */
    },
    computed: {
        ...mapGetters(["currentUser"]),
        environmentTag() {
            let appEnv;
            let vueAppEnvName = process.env.VUE_APP_ENV_NAME.toUpperCase();
            switch (vueAppEnvName) {
                case "STAGE":
                case "STAGING":
                case "TEST":
                    appEnv = "TEST";
                    break;
                case "PROD":
                case "PRODUCTION":
                    appEnv = "";
                    break;
                default:
                    appEnv = "DEV";
            }
            return appEnv;
        },
        isDeviceMobile() {
            let userAgent = navigator.userAgent || window.opera;
            return this.isUserAgentMobile(userAgent);
        },
        isCurrentPathUnauthorized() {
            return this.$route.path !== "/unauthorized";
        },
    },
    methods: {
        updatedNetworkResponseTime(value) {
            this.averageNetworkResponseTime = value;
        },
        doesUserHaveRole(...roles) {
            if (!this.currentUser) return false;

            return this.currentUser.userRoles.some((userRole) => roles.includes(userRole.roleID));
        },
        isUserAgentMobile(userAgent) {
            /// Identifies Mobile Device
            let mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            return mobileRegex.test(userAgent);
        },
    },
};
</script>

<style lang="scss" scoped>
#ReHeader {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
    padding: 0;
    margin: auto;
}

.router-link-active {
    color: rgb(0, 0, 0, 0.9) !important;
}
</style>
